@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta+Malar&family=Rajdhani:wght@500&display=swap');


h5{
    font-family: 'Mukta Malar', sans-serif;
    color: black;
    margin-top: 0;
}

h4{
    font-weight: 1000;
    color: #6F1E51;
    margin-bottom: 0;
}

.ql{
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold 800;
    font-size: larger;
}

.doctors{
    margin: 4rem 6rem;
    color:#595972;
}



.trip h1{
    font-size: 3rem;
}

.doctor-card{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.d-card{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.11);
    border-radius: 7px;
    cursor: pointer;
    padding:1rem .8rem;
}

.d-image{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.d-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.d-image:hover img{
    transform: scale(1.3);
}

.d-card h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
}

@media screen and (max-width:850px) {
    .doctors {
        margin: 4rem 2rem;
      }
    
      .doctor-card {
        grid-template-columns: repeat(1, 100%);
      }
    
      .d-card {
        width: 100%;
        margin-bottom: 1.5rem;
      }
}

.xxx{
    color: rgb(69, 58, 90);
  }
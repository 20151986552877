/* ImageSlider.css */

.slider-container {
  position: relative;
  width: 100%;
  max-width: 650px; /* Adjust the max-width as needed */
  margin: auto;
  overflow: hidden;
}

.slide {
  display: none;
  transition: opacity 0.5s ease-in-out;
}

.active {
  display: block;
}

.slider-image{
  width: 100%;
  height: auto;
}

.slide-text {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px; /* Add some margin to create space between text and button */
}

.center-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #0d3491;
  border-color: #002376;
  border: 2px solid #002376;
  /* border-style: ; */
  cursor: pointer;
  font-weight: 1000;
  border-radius: 5px;
  width: 150px;
}

.center-button:hover{
  background-color: #002376;
  color: #ffffff;
}

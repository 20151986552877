
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
.doctors {
    margin: 4rem 6rem;
    color: #777070;
  }
  
  .doctor-card {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    gap: 0;
  }
  
  .d-card {
    margin-top: 15px;
    width: 100%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(40, 134, 128, 0.165);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
  }
  
  .d-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .d-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
  }
  
  .d-image:hover img {
    transform: scale(1.3);
  }
  
  .d-card h4 {
    font-size: 1.3rem;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  @media screen and (max-width: 850px) {
    .doctors {
      margin: 4rem 2rem;
    }
  
    .doctor-card {
      grid-template-columns: repeat(1, 100%);
    }
  
    .d-card {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  
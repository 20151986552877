@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Doppio+One&family=Mukta+Malar&family=Quintessential&family=Racing+Sans+One&family=Rajdhani:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asar&family=Doppio+One&family=Mukta+Malar&family=Quintessential&family=Racing+Sans+One&family=Rajdhani:wght@500&display=swap');


.form-container{
    margin: 4rem 6rem;
    color: rgb(58, 40, 93, 0.8);
}

h1{
    font-family: 'Asar', serif;
    font-weight:bold;
}

.form-container form{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}

.form-container input{
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    border: 0.7px solid #000453;}

.form-container textarea{
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    border: 0.7px solid #000453;
}

button {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0d3491;
    border: 2px solid #002376;
    cursor: pointer;
    font-weight: 1000;
    border-radius: 5px;
  }
  
button:hover {
    background-color: #002376;
    color: #ffffff;
  }

@media screen  and (max-width:850px){
    .form-container{
        margin: 4rem 6rem;

    }

    .form-container form{
        padding-top: 2rem;
        width: 90%;
    }
    
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.about-container{
    margin: 4rem 6rem;
    text-align: start;
}

.about-container h1{
    padding-bottom: 1rem;
    
}

.about-container p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    padding-bottom: 2rem;
    color: rgb(104, 90, 116);
}

@media screen and (max-width: 850px){
    .about-container{
    margin: 4rem 2rem;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@800&family=Poppins:wght@600&family=Rajdhani:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@900&display=swap');

.hero {
    width: auto;
    height: 90vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 90vh;
    position: relative;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1 {
    color: white;
    text-shadow: -2px -1px 6px #292929;
    position: relative;
    font-family: 'Anton', sans-serif;
    font-size: 5.5rem;
    font-weight: 1000;
    background: linear-gradient(#ffffff, #f0f0f0) no-repeat, #585858;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
}

.hero-text p {
    font-family: 'Raleway', sans-serif;
    font-size: 1.3rem;
    font-weight: 800;
    padding: 0 0 2rem 0;
    text-shadow: -1px -1px 3px #646464;
    background: linear-gradient(90deg, #ffffff, hsla(182, 100%, 58%, 0.408));
    background-clip: text;
    -webkit-background-clip: text;
    color: white;
}

@media screen and (max-width: 555px) {
    .hero-text {
        padding: 10px 20px;
    }

    .hero-text h1 {
        font-size: 4rem; /* Adjust font size for smaller screens */
    }

    .hero-text p {
        font-size: 1rem; /* Adjust font size for smaller screens */
        padding: 0 0 1.5rem 0;
    }
    
    img {
        width: 80%; /* Adjust image size for smaller screens */
    }
}

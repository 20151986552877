.footer{
    background-color: #d5e4e8;
    padding: 4rem 6rem ;
    color: #2f3d48;

}

.top{
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top i{
    color: #485460;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i :hover{
    color: #273c75;
}

.bottom{
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div{
    display: flex;
    flex-direction: column;
}

.bottom h4{
    font-size: 1.5rem;
    padding: 1rem 0 0 0.8rem 0;
    color:rgb(59, 76, 104);
}

.bottom p{
    text-decoration: none;
    color: #5354609f;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}

.line {
    width: 100%;
    height: 1px;
    background-color: black;
  }

  @media screen and (max-width:850px){
    .footer{
        padding: 4rem 2rem;
    }

    .footer i{
        margin: 1rem 1rem 0 0;
    }

    .bottom div{
        width: 50%;
    }
  }
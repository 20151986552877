.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.topic {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.5em;
    font-weight: 8px;
    color: #333;
    background-color: #dbd9d9c4;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.topic:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}



.content {
    display: flex;
    flex-wrap: wrap;
}

.image {
    flex: 1;
    margin-right: 20px;
    overflow: hidden; /* Ensures the shadow doesn't overflow the container */
    transition: box-shadow 0.3s ease-in-out;
}

.image:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.text {
    flex: 1;
    text-align: justify;
}

.image {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
    }

    .image,
    .text {
        width: 100%;
    }
}



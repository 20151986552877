.welcome {
    width: 99%;
    height: 100%;
    position: relative;
    margin: 4rem 0.5rem;
  }
  
  .set {
    display: flex;
    justify-content: space-between;
  }
  
  .left-column {
    width: 50%; /* Adjust as needed */
  }
  
  .right-column {
    width: 45%; /* Adjust as needed */
  }
  
  .head {
    margin-bottom: 0;
  }
  
  .head h1 {
    font-family: 'Archivo', sans-serif;
    font-weight: 700;
    color: rgb(58, 40, 93, 0.8);
    font-size: 2.7rem;
    text-align: left;
  }
  
  .bset {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    text-decoration: none;
  }
  
  .bset button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0d3491;
    border: 2px solid #002376;
    cursor: pointer;
    font-weight: 1000;
    border-radius: 5px;
  }
  
  .bset button:hover {
    background-color: #002376;
    color: #ffffff;
  }
  
  .pset {
    color: #555555;
    display: flex;
    align-items: flex-start;
    text-align: justify;
    margin-top: 10px;
    margin-left: 10px;
    text-align: start;
    width: 80%; /* Adjust as needed */
  }
  
  .image {
    margin-right: 160px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 400px;
    border-radius: 10px;
  }
  
  .image img {
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
  }
  
  @media screen and (max-width: 850px) {
    .set {
      flex-direction: column;
    }
  
    .left-column,
    .right-column {
      width: 100%;
    }
  
    .bset {
      margin-top: 2rem;
    }
  }
  
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,500;1,100;1,200;1,400;1,500;1,600;1,700&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}


@import url('https://fonts.googleapis.com/css2?family=Mukta+Malar&family=Quintessential&family=Racing+Sans+One&family=Rajdhani:wght@500&display=swap');

.NavbarItems{
    font-family: 'Quintessential', serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    background-color: #fff;
}

.navbar-logo img {
    max-height: 140px; /* Adjust the height as needed */
    max-width: 260px; /* Adjust the width as needed */
    object-fit: contain; /* This ensures the image maintains its aspect ratio */
  }

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap:10px;
    list-style: none;
    align-items: center;
}

.nav-links{
    text-decoration: none;
    color:#EA2027;
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0.25rem 1rem;
    white-space: nowrap;
    align-items: center;
}

.nav-links i{
    padding-right: 10px;
    color: #1e3799;
}

.nav-links:hover{
    background-color: #EA2027;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile{
    display: none;
}

.menu-icons{
    display: none;
}
@media screen and (max-width:850px){
    .NavbarItems{
        z-index: 99;
    }


    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background:  #fff;
        width: 100%;
        height: auto;
        position: absolute;
        list-style: none;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
    }

    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .menu-icons{
        display: block;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 1.2rem;
        color: #222;
    }

    .nav-links:hover{
        background-color: #EA2027;
        color: #fff;
        border-radius: 4px;
        transition: none;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        text-decoration: none;
        width: 80%;
        background: #EA2027;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
    }
    

}